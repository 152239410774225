export type AnimateFunc = (node: Element, animationName: string) => void;
/**
 * Animate html elements with the help of the library Animate.css
 * https://animate.style/
 *
 * @param {*} node
 * @param {String} animationName
 */
export default function (node: Element, animationName: string) {
  node.classList.add('animate__animated', `animate__${animationName}`);

  function handleAnimationEnd() {
    node.classList.remove('animate__animated', `animate__${animationName}`);
    node.removeEventListener('animationend', handleAnimationEnd);
  }

  node.addEventListener('animationend', handleAnimationEnd);
}
