import {
  UserApi,
  UserJsonldBillingUserRead as User,
} from '@gyhtiot/api-client';
import { AxiosInstance, AxiosResponse } from 'axios';

export default class UserService {
  private api: UserApi;

  constructor(basePath: string, axios: AxiosInstance) {
    this.api = new UserApi(undefined, basePath, axios);
  }

  public getUser = (
    id: string
  ): Promise<AxiosResponse<User>> => {
    return this.api.apiOrgusersIdGet({ id });
  };
}
