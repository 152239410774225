import { RouteRecordRaw } from 'vue-router';
import Invoices from './invoices/router';

const routes: RouteRecordRaw[] = [
  {
    path: 'contracts',
    component: () => import('../../EmptyRouterView.vue'),
    name: 'Contracts',
    children: [
      ...Invoices,
      {
        path: '',
        component: () => import('./Contracts.vue'),
        name: 'Contracts--ignore',
      },
      {
        path: 'create',
        component: () => import('./Create.vue'),
        name: 'create--Contract--ignore',
      },
      {
        path: ':contractId',
        component: () => import('../../EmptyRouterView.vue'),
        name: 'Contract',
        children: [
          {
            path: '',
            component: () => import('./Contract.vue'),
            name: 'Contract--ignore',
          },
          {
            path: 'upload',
            component: () => import('./Upload.vue'),
            name: 'Upload--Contract--ignore',
          },
          {
            path: 'raise',
            component: () => import('./Raise.vue'),
            name: 'Raise--Contract--ignore',
          }
        ],
      },
    ],
  },
];

export default routes;
