import Customers from '../billing/customers/router';
import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'billing',
    component: () => import('../EmptyRouterView.vue'),
    name: 'Billing--ignore',
    children: [...Customers],
  },
];

export default routes;
