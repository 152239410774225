import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import useAuthStore from '../store/auth';
import Billing from '../views/billing/router';
import Dashboard from '../views/dashboard/router';
import Organization from '../views/organization/router';
import Rental from '../views/rental/router';

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue'),
  },
  {
    path: '/',
    component: () => import('../views/Index.vue'),
    redirect: '/dashboard',
    name: 'Home',
    children: [
      ...Billing,
      ...Dashboard,
      ...Rental,
      ...Organization,
      {
        path: '/:pathMatch(.*)*',
        component: () => import('../views/error/NotFound.vue'),
        name: 'Not Found',
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore();

  if (!authStore.isInitialized) {
    authStore.initialize();
  }

  const isAuthenticated = !!authStore.token;

  if (!isAuthenticated && to.name !== 'Login') {
    next({ name: 'Login' });
  } else if (isAuthenticated && to.name === 'Login') {
    next({ name: 'Dashboard' });
  } else {
    next();
  }
});

export default router;
