import { RouteRecordRaw } from 'vue-router';
import User from './user/router';

const routes: RouteRecordRaw[] = [
  {
    path: 'org/organizations',
    component: () => import('../EmptyRouterView.vue'),
    name: 'Organizations',
    children: [
      ...User,
      {
        path: '',
        component: () => import('./Organizations.vue'),
        name: 'Organizations--ignore',
      },
      {
        path: ':organizationId',
        component: () => import('./Organization.vue'),
        name: 'Organization',
      },
    ],
  },
];

export default routes;
