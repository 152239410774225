import {
  AttachmentApi,
  AttachmentApiApiRentalcontractsattachmentsIdpdfGetRequest as PdfRequest,
  ContractApi,
  ContractApiApiRentalcontractsIdattachmentsPostRequest as PdfUploadRequest,
} from '@gyhtiot/api-client';
import { AxiosInstance, AxiosResponse } from 'axios';

export default class ContractService {
  private api: AttachmentApi;
  private cApi: ContractApi;

  constructor(basePath: string, axios: AxiosInstance) {
    this.api = new AttachmentApi(undefined, basePath, axios);
    this.cApi = new ContractApi(undefined, basePath, axios);
  }

  public getAttachment = (params: PdfRequest): Promise<AxiosResponse<File>> => {
    return this.api.apiRentalcontractsattachmentsIdpdfGet(params, {
      responseType: 'blob',
    });
  };

  public postAttachment = (
    params: PdfUploadRequest
  ): Promise<AxiosResponse<any>> => {
    return this.cApi.apiRentalcontractsIdattachmentsPost(params);
  };
}
