import {
  ApiCustomersGetCollection200Response,
  CustomerApi,
  CustomerBillingCustomerWrite,
  CustomerJsonldBillingCustomerRead,
  CustomerJsonldBillingCustomerWrite,
  CustomerApiApiCustomersGetCollectionRequest,
} from '@gyhtiot/api-client';
import { AxiosInstance, AxiosResponse } from 'axios';

export default class CustomerService {
  private api: CustomerApi;

  constructor(basePath: string, axios: AxiosInstance) {
    this.api = new CustomerApi(undefined, basePath, axios);
  }

  public getCustomers = (
    params?: CustomerApiApiCustomersGetCollectionRequest
  ): Promise<AxiosResponse<ApiCustomersGetCollection200Response>> => {
    return this.api.apiBillingcustomersGetCollection(params);
  };

  public getCustomer = (
    id: string
  ): Promise<AxiosResponse<CustomerJsonldBillingCustomerRead>> => {
    return this.api.apiBillingcustomersIdGet({ id });
  };

  public postCustomer = (
    customerJsonldBillingCustomerWrite: CustomerJsonldBillingCustomerWrite
  ): Promise<AxiosResponse<CustomerJsonldBillingCustomerRead>> => {
    return this.api.apiBillingcustomersPost({
      customerJsonldBillingCustomerWrite,
    });
  };

  public putCustomer = (
    id: string,
    customerJsonldBillingCustomerWrite: CustomerJsonldBillingCustomerWrite
  ): Promise<AxiosResponse<CustomerJsonldBillingCustomerRead>> => {
    return this.api.apiBillingcustomersIdPut({
      id,
      customerJsonldBillingCustomerWrite,
    });
  };

  public patchCustomer = (
    id: string,
    customerBillingCustomerWrite: CustomerBillingCustomerWrite
  ): Promise<AxiosResponse<CustomerJsonldBillingCustomerRead>> => {
    return this.api.apiBillingcustomersIdPatch({
      id,
      customerBillingCustomerWrite,
    });
  };
}
