import axios, { AxiosInstance } from 'axios';
import useAuthStore from '../../store/auth';
import {LoginCheckApi} from "@gyhtiot/api-client";

const create = (basePath: string): AxiosInstance => {
  const instance = axios.create({ baseURL: basePath });

  instance.interceptors.request.use(
    async (config) => {
      const authStore = useAuthStore();
      const token = authStore.token;
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401) {
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          if (!await refresh(basePath)) {
            await forceLogin()
            return Promise.reject(error);
          }

          return instance(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

const forceLogin = async (): Promise<void> => {
  const authStore = useAuthStore();
  authStore.setToken(null);
  await location.replace('/login');
}

const refresh = async (basePath: string): Promise<boolean> => {
  const authStore = useAuthStore();
  const token: String = authStore.refreshToken;
  const api = new LoginCheckApi(undefined, basePath);

  try {
    const r = await api.postRefreshItem({ tokenRefresh: { refresh_token: token } });
    authStore.setToken(r.data.token, r.data.refresh_token);
    return true;
  } catch (e) {
    return false;
  }
};

export default create;
