import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import {App} from "@vue/runtime-core";
import {Router} from "vue-router";

export default function (app: App, router: Router) {
    if (import.meta.env.VITE_SENTRY_DISABLED !== undefined) {
        return;
    }

    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_ENV,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ["localhost", import.meta.env.VITE_HOST, /^\//],
            }),
            new Sentry.Replay()
        ],
        tracesSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        autoSessionTracking: true,
        // Vue performance tracking
        trackComponents: true,
        hooks: ["activate", "create", "mount", "update", "destroy"], // Track all
    });
}
