import {
  InvoiceApi,
  InvoiceJsonldRentalInvoiceReadBillingInvoiceReadDetailed as InvoiceItem,
  ApiRentalcontractsCidinvoicesGetCollection200Response as InvoiceCollection,
  InvoiceApiApiRentalcontractsCidinvoicesGetCollectionRequest as InvoicesByContractParams,
} from '@gyhtiot/api-client';
import { AxiosInstance, AxiosResponse } from 'axios';
import {} from '@gyhtiot/api-client/dist/api';

export default class InvoiceService {
  private api: InvoiceApi;

  constructor(basePath: string, axios: AxiosInstance) {
    this.api = new InvoiceApi(undefined, basePath, axios);
  }

  public getInvoice = (
    id: string
  ): Promise<AxiosResponse<InvoiceItem>> => {
    return this.api.apiRentalcontractsinvoicesIdGet({ id });
  };

  public getInvoicesByContractId = (
    params: InvoicesByContractParams
  ): Promise<AxiosResponse<InvoiceCollection>> => {
    return this.api.apiRentalcontractsCidinvoicesGetCollection(params);
  };

  public getInvoiceFile = (
    id: string
  ): Promise<AxiosResponse<File>> => {
    return this.api.apiRentalcontractsinvoicesIdpdfGet({ id: id, download: true }, { responseType: 'blob' })
  };
}
