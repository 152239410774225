export interface Currency {
  label: string;
  decimalDigits: number;
  decimalSeparator: string;
  thousandsSeparator: string;
  currencySymbol: string;
  currencySymbolNumberOfSpaces: number;
  currencySymbolPosition: string;
}

export enum CurrencyEnum {
  EUR = 'eur',
  USD = 'usd',
}

export const currencyInformation: Record<CurrencyEnum, Currency> = {
  [CurrencyEnum.EUR]: {
    label: 'EUR',
    decimalDigits: 2,
    decimalSeparator: '.',
    thousandsSeparator: ',',
    currencySymbol: '€',
    currencySymbolNumberOfSpaces: 1,
    currencySymbolPosition: 'right',
  },
  [CurrencyEnum.USD]: {
    label: 'USD',
    decimalDigits: 2,
    decimalSeparator: '.',
    thousandsSeparator: ',',
    currencySymbol: '$',
    currencySymbolNumberOfSpaces: 0,
    currencySymbolPosition: 'left',
  },
};
