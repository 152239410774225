<template>
  <div
    aria-live="assertive"
    class="fixed inset-y-16 inset-x-10 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition
        v-for="(obj, i) in notifications"
        :key="i"
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
      >
        <div
          v-if="obj.show"
          class="max-w-sm w-full bg-white dark:bg-slate-800 dark:ring-slate-200 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  v-if="obj.notification.type === 'success'"
                  class="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
                <InformationCircleIcon
                  v-if="obj.notification.type === 'info'"
                  class="h-6 w-6 text-blue-400"
                  aria-hidden="true"
                />
                <ExclamationCircleIcon
                  v-if="obj.notification.type === 'warning'"
                  class="h-6 w-6 text-yellow-400"
                  aria-hidden="true"
                />
                <XCircleIcon
                  v-if="obj.notification.type === 'danger'"
                  class="h-6 w-6 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p
                  class="text-sm font-medium text-gray-900 dark:text-slate-200"
                >
                  {{ obj.notification.title }}
                </p>
                <p class="mt-1 text-sm text-gray-500 dark:text-slate-400">
                  {{ obj.notification.description }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  type="button"
                  class="bg-white dark:bg-slate-800 rounded-md inline-flex text-gray-400 dark:text-slate-400 dark:hover:text-slate-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="hide(i)"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, nextTick } from 'vue';
import {
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { v4 } from 'uuid';
import { Emitter, EventType } from 'mitt';

export interface Events extends Record<EventType, unknown> {
  notify: Notification;
}

export type IEmitter = Emitter<Events>;

export interface Notification {
  title: string;
  description: string;
  type: 'success' | 'info' | 'warning' | 'danger';
}

const notifications = ref<
  Record<
    string,
    { notification: Notification; show: boolean; timeout: NodeJS.Timeout }
  >
>({});

const emitter = inject<IEmitter>('emitter');

function clear(id: string) {
  notifications.value[id].show = false;
  clearTimeout(notifications.value[id].timeout);
  const clear = setTimeout(() => remove, 100);
  notifications.value[id].timeout = clear;
}

function remove(id: string) {
  delete notifications.value[id];
}

onMounted(() => {
  if (emitter) {
    emitter.on('notify', (notification: Notification) => {
      const id = v4();
      const timeout = setTimeout(() => clear(id), 5000);

      notifications.value[id] = {
        notification: notification,
        show: false,
        timeout: timeout,
      };

      nextTick(() => {
        notifications.value[id].show = true;
      });
    });
  }
});

function hide(id: string) {
  const n = notifications.value[id];
  if (n && n.timeout) {
    clearTimeout(n.timeout);
  }

  if (n) {
    clear(id);
  }
}
</script>
