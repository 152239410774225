import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'invoices',
    component: () => import('../../../EmptyRouterView.vue'),
    name: 'Invoices',
    children: [
      // {
      //   path: '',
      //   component: () => import('./Invoices.vue'),
      //   name: 'Invoices--ignore',
      // },
      {
        path: ':invoiceId',
        component: () => import('../../../EmptyRouterView.vue'),
        name: 'Invoice',
        children: [
          {
            path: '',
            component: () => import('./Invoice.vue'),
            name: 'Invoice--ignore',
          },
        ],
      },
    ],
  },
];

export default routes;
