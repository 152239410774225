export default {
  common: {
    add: 'Lisää',
    cancel: 'Peruuta',
    description: 'Kuvaus',
    delete: 'Poista',
    title: 'Otsikko',
    save: 'Tallena',
    contact: 'Ota yhteyttä',
    price: 'Hinta',
    yes: 'Kyllä',
    no: 'Ei',
    copy: 'Kopioi',
    next: 'Seuraava',
    previous: 'Edellinen',
  },
  events: {
    copied: 'Kopioitu',
    copied_clipboard: 'Kopioitu leikepöydälle',
  },
  error: {
    notfound: 'Sivua ei löydy',
    other: 'Tapahtui virhe',
  },
  login: {
    title: 'Kirjaudu sisään',
    button: 'Sign In',
    orsign: 'Or sign in with',
  },
  utility: {
    maintenance: 'Huoltotila',
  },
  menu: {
    search: 'Etsi (ctrl + f)',
    dashboard: 'Työpöytä',
    logout: 'Kirjaudu ulos',
    profile: 'Profiili',
    blank: 'Tyhjä sivu',
    pages: 'Pages',
    others: 'Others',
    email: 'Outlook',
    chat: 'Teams',
    todo: 'To Do',
    board: 'Planner',
    users: 'Users',
    usersList: 'List',
    usersEdit: 'Edit',
    ecommerce: 'Ecommerce',
    ecommerceList: 'Products',
    ecommerceProductDetails: 'Product Details',
    ecommerceOrders: 'Orders',
    ecommerceCart: 'Cart',
    auth: 'Auth Pages',
    authLogin: 'Signin / Login',
    authRegister: 'Signup / Register',
    authVerify: 'Verify Email',
    authForgot: 'Forgot Password',
    authReset: 'Reset Password',
    errorPages: 'Error Pages',
    errorNotFound: 'Not Found / 404',
    errorUnexpected: 'Unexpected / 500',
    utilityPages: 'Utility Pages',
    utilityMaintenance: 'Maintenance',
    utilitySoon: 'Coming Soon',
    utilityHelp: 'FAQs / Help',
    levels: 'Menu Levels',
    disabled: 'Menu Disabled',
    docs: 'Documentation',
  },
};
