import ApartmentService from '../services/intra/ApartmentService';
import AuthenticationService from '../services/intra/AuthenticationService';
import BuildingService from '../services/intra/BuildingService';
import ContractService from '../services/intra/ContractService';
import ContractAttachmentService from '../services/intra/ContractAttachmentService';
import CustomerService from '../services/intra/CustomerService';
import create from '../services/intra/HttpClient';
import UserService from '../services/intra/UserService';
import {AxiosError} from "axios";
// @ts-ignore
import {Events, IEmitter} from "../components/common/Notification.vue";
import {Emitter} from "mitt";
import InvoiceService from "../services/intra/InvoiceService";
import RentalAnalyticsService from "../services/intra/RentalAnalyticsService";


const host = import.meta.env.VITE_API;
const basePath = host.startsWith('localhost')
    ? `http://${host}`.replace(/\/+$/, '')
    : `https://${host}`.replace(/\/+$/, '')

const axiosErrorHandler = (e: AxiosError, emitter: Emitter<Events>) => {
  emitter.emit('notify', {
    // @ts-ignore
    title: <string>e.response?.data?.['hydra:title'] || 'Failure!',
    // @ts-ignore
    description: <string>e.response?.data?.['hydra:description']+'.' || 'Something went wrong.',
    type: 'danger',
  });
}

export interface IntraI {
  apartment: ApartmentService;
  authentication: AuthenticationService;
  building: BuildingService;
  contract: ContractService;
  contractAttachments: ContractAttachmentService;
  customer: CustomerService;
  invoice: InvoiceService;
  rentalAnalytics: RentalAnalyticsService;
  user: UserService;
  emitError: (error: AxiosError, emitter: Emitter<Events>|undefined) => void;
}

export default () => {
  const axios = create(basePath);

  return {
    apartment: new ApartmentService(basePath, axios),
    authentication: new AuthenticationService(basePath),
    building: new BuildingService(basePath, axios),
    contract: new ContractService(basePath, axios),
    contractAttachments: new ContractAttachmentService(basePath, axios),
    customer: new CustomerService(basePath, axios),
    invoice: new InvoiceService(basePath, axios),
    rentalAnalytics: new RentalAnalyticsService(basePath, axios),
    user: new UserService(basePath, axios),
    emitError: axiosErrorHandler,
  };
};
