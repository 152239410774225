import {LoginCheckApi, UserApi} from '@gyhtiot/api-client';
import { useAuthStore } from '../../store/auth';

export default class AuthenticationService {
  public api: LoginCheckApi;
  private userApi: UserApi;

  constructor(basePath: string) {
    this.api = new LoginCheckApi(undefined, basePath);
    this.userApi = new UserApi(undefined, basePath);
  }

  public async login(username: string, password: string, remember_me: boolean): Promise<string> {
    const authStore = useAuthStore();
    const response = await this.api.postCredentialsItem({ credentials: {username, password, remember_me}});
    const token = response.data.token;

    if (!token) {
      throw new Error('Token not provided');
    }

    authStore.setToken(token, response.data.refresh_token);

    const userResponse = await this.userApi.apiOrgusersIdGet(
      { id: <String>authStore.uid },
      { headers: { Authorization: `Bearer ${token}` } }
    )

    if (userResponse.status !== 200) {
      throw new Error('User data fetch failed');
    }

    authStore.setUser(userResponse.data);

    return token;
  }

  public async switchOrganization(connection_id: String): Promise<void> {
    const authStore = useAuthStore();
    const token = authStore.token;
    const response = await this.userApi.apiOrguserschangeOrgPatch(
      { apiOrguserschangeOrgPatchRequest: { connection_id }},
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.status !== 200) {
      throw new Error('Failed to switch organization');
    }

    authStore.setUser(response.data);
  }

  public async logout(): Promise<boolean> {
    const authStore = useAuthStore();
    const refresh_token: String = authStore.refreshToken;
    const response = await this.api.postLogoutItem({ tokenRefresh: { refresh_token }});

    return response.status === 200;
  }
}
