import { I18n, createI18n, LocaleMessages, VueMessageType } from 'vue-i18n';
import { useCoreStore } from '../store/core';
import en from '../../translations/en';
import fi from '../../translations/fi';
import { App } from 'vue';

export const availableLocales = [
  {
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en,
  },
  {
    code: 'fi',
    flag: 'fi',
    label: 'Suomi',
    messages: fi,
  },
];

const messages: LocaleMessages<VueMessageType> = {};

availableLocales.forEach((l) => {
  messages[l.code] = l.messages;
});

export function installI18n(app: App<Element>) {
  const i18n = createI18n({
    locale: useCoreStore().locale,
    fallbackLocale: 'en',
    messages,
  });
  app.use(i18n);
  return i18n;
}

export function postInstallI18n(
  i18n: I18n<LocaleMessages<VueMessageType>, unknown, unknown, true>
) {
  const coreStore = useCoreStore();

  coreStore.$subscribe((_, state) => {
    i18n.global.locale = state.locale;
    localStorage.setItem('locale', state.locale);
  });

  const foundLocale = localStorage.getItem('locale');

  if (foundLocale) {
    coreStore.locale = foundLocale;
  } else {
    try {
      return; //disabled for now

      // get browser default language
      const { 0: browserLang } = navigator.language.split('-');

      const supported = availableLocales.map((l) => l.code);
      if (supported.includes(browserLang)) {
        coreStore.locale = browserLang;
      }
    } catch (e) {
      return;
    }
  }
}
