import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'users',
    component: () => import('../../EmptyRouterView.vue'),
    name: 'Users',
    children: [
      {
        path: ':userId',
        component: () => import('./User.vue'),
        name: 'User--ignore',
      },
      // {
      //   path: ':userId',
      //   component: () => import('./Organization.vue'),
      //   name: 'Organization',
      // },
    ],
  },
];

export default routes;
