<template>
  <VueTailwindDatepicker
    as-single
    v-model="date"
    :formatter="{ date: formatDate, month: formatMonth}"
    :placeholder="control.placeholder"
    input-classes="bg-s-700 appearance-none block w-full px-3 py-2 border border-slate-700 rounded-md placeholder-slate-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-slate-200"
  />
</template>

<script setup lang="ts">
import {onMounted, PropType, ref, UnwrapRef, watch} from "vue";
import VueTailwindDatepicker from 'vue-tailwind-datepicker'
import FormDate from "../domain/FormDate";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

const props = defineProps({
  control: { type: Object as PropType<FormDate>, required: true },
  formatDate: { type: String, default: 'DD.MM.YYYY' },
  formatMonth: { type: String, default: 'MMMM' },
})
const emits = defineEmits(["update:modelValue"]);
const date = new ref<String[]>([])
watch(date, () => {

  console.log(date.value);
  emits('update:modelValue', date.value.map(date => dayjs.utc(date, props.formatDate).format()))
});

onMounted(() => {
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
})
</script>
