import Buildings from './buildings/router';
import Contracts from './contracts/router';
import Apartments from './apartments/router';
import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'rental',
    component: () => import('../EmptyRouterView.vue'),
    name: 'Rental--ignore',
    children: [...Buildings, ...Apartments, ...Contracts],
  },
];

export default routes;
