<template>
  <div class="flex gap-x-2 items-center">
    <label
      :for="control.id"
      @drop="onDrop($event)"
      @dragover="onDragOver($event)"
      class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
    >
      <div
        class="flex flex-col items-center justify-center pt-5 pb-6"
        v-if="!isSelected"
      >
        <svg
          aria-hidden="true"
          class="w-10 h-10 mb-3 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          ></path>
        </svg>
        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
          <span class="font-semibold">Click to upload</span> or drag and drop
        </p>
        <p class="text-xs text-gray-500 dark:text-gray-400">
          {{ control.title }}
        </p>
      </div>
      <div v-if="isSelected">
        <div class="list-none hover:list-dis flex flex-col items-center">
          <DocumentCheckIcon /> {{ file.name }} <br />
          <small>{{ roundTwoDecimals(file.size / 1000) }}kb</small>
        </div>
      </div>
      <input
        :id="control.id"
        :name="control.name"
        type="file"
        class="hidden"
        @input="onInput($event)"
        accept="application/pdf"
      />
    </label>
  </div>
</template>

<script setup lang="ts">
import FormCheckbox from '../domain/FormCheckbox';
import { Ref, ref } from 'vue';
import { DocumentCheckIcon } from '@heroicons/vue/24/outline';

defineProps({
  control: null
});
const emit = defineEmits(["update:modelValue"]);
const isSelected: Ref<Boolean> = ref(false);
const file: Ref<File | undefined> = ref(undefined);

const onFileAdded = (uploaded: File) => {
  file.value = uploaded;
  isSelected.value = true;
  emit('update:modelValue', file.value);
  //this.$emit('update:modelValue', (event.target as HTMLInputElement).value)
};

const onInput = (event) => {
  onFileAdded(event.target.files[0]);
};
const onDrop = (event) => {
  event.preventDefault();
  onFileAdded(event.dataTransfer.files[0]);
};
const onDragOver = (event) => {
  event.preventDefault();
};

const roundTwoDecimals = (value: number) => {
  return value.toFixed(2);
};
</script>
