import { defineStore } from 'pinia';
import jwt_decode from 'jwt-decode';
import * as Sentry from "@sentry/vue";
import { UsersJsonldOrgUserReadConnectionRead } from "@gyhtiot/api-client";

interface JWTDecoded {
  iat: number;
  exp: number;
  roles: Array<string>;
  username: string;
  uid: number;
}

const LOCAL_STORAGE_AUTH_KEY = 'jwtToken';
const LOCAL_STORAGE_REFRESH_KEY = 'jwtRefreshToken';
const LOCAL_STORAGE_USER = 'user';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null as string | null,
    refreshToken: null as string | null,
    user: null as UsersJsonldOrgUserReadConnectionRead | null,
    isInitialized: false,
  }),
  getters: {
    jwtData(): JWTDecoded | null {
      if (this.token) {
        return jwt_decode<JWTDecoded>(this.token);
      }

      return null;
    },
    uid(): number | undefined {
      return this.jwtData?.uid;
    },
  },
  actions: {
    setToken(
      token: string | null,
      refreshToken: string | null = null,
    ) {
      if (!token && localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)) {
        localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        Sentry.setUser(null);
        if (localStorage.getItem(LOCAL_STORAGE_REFRESH_KEY)) {
          localStorage.removeItem(LOCAL_STORAGE_REFRESH_KEY);
        }
        if (localStorage.getItem(LOCAL_STORAGE_USER)) {
          localStorage.removeItem(LOCAL_STORAGE_USER);
        }
      }

      if (token) {
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, token);
        const data = this.jwtData;
        Sentry.setUser({
          email: data?.username,
          id: data?.uid.toString(),
        })
      }

      if (refreshToken) {
        localStorage.setItem(LOCAL_STORAGE_REFRESH_KEY, refreshToken);
      }

      this.token = token;
      this.refreshToken = refreshToken;
    },
    setUser(user: UsersJsonldOrgUserReadConnectionRead|null) {
      if (user) {
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
        this.user = user;
      }
    },
    initialize() {
      if (!this.isInitialized) {
        const token = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
        const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_KEY);
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER) ?? '{}');

        if (token) {
          this.token = token;
          this.refreshToken = refreshToken;
          this.user = user;
        }

        this.isInitialized = true;
      }
    },
  },
});

export default useAuthStore;
