const routes = [
  {
    path: 'customers',
    component: () => import('../../EmptyRouterView.vue'),
    name: 'Customers',
    children: [
      {
        path: '',
        component: () => import('./Customers.vue'),
        name: 'Customers--ignore',
      },
      {
        path: ':customerId',
        component: () => import('./Customer.vue'),
        name: 'Customer',
      },
    ],
  },
];

export default routes;
