<template>
  <router-view></router-view>
  <Notification></Notification>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { initFlowbite } from 'flowbite';
import Notification from './components/common/Notification.vue';

onMounted(() => {
  initFlowbite();
})
</script>

<style lang="scss">
html.dark {
  color-scheme: dark;
}
</style>
