import {
  RentalAnalyticsApi,
} from '@gyhtiot/api-client';
import { AxiosInstance, AxiosResponse } from 'axios';
import {RentalAnalyticsOverview} from "@gyhtiot/api-client/dist/api";

export default class RentalAnalyticsService {
  private api: RentalAnalyticsApi;

  constructor(basePath: string, axios: AxiosInstance) {
    this.api = new RentalAnalyticsApi(undefined, basePath, axios);
  }
  public getAnalyticOverview = (): Promise<AxiosResponse<RentalAnalyticsOverview>> => {
    return this.api.getRentalAnalyticsOverview();
  };
}
