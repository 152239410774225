import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'apartments',
    component: () => import('../../EmptyRouterView.vue'),
    name: 'Apartments',
    children: [
      // {
      //   path: 'add',
      //   component: () => import('../../EmptyRouterView.vue'),
      //   name: 'Add',
      //   children: [
      //     {
      //       path: '',
      //       component: () => import('./Add.vue'),
      //       name: 'Add--ignore',
      //     },
      //   ],
      // },
      {
        path: ':apartmentId',
        component: () => import('../../EmptyRouterView.vue'),
        name: 'Inspect Apartment',
        children: [
          {
            path: '',
            component: () => import('./Apartment.vue'),
            name: 'Apartment--Inspect--ignore',
          },
          {
            path: 'edit',
            component: () => import('../../EmptyRouterView.vue'),
            name: 'Edit Apartment',
            props: true,
            children: [
              {
                path: '',
                component: () => import('./Edit.vue'),
                name: 'Apartment--Edit--ignore',
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
