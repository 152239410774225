export default {
  common: {
    add: 'Add',
    cancel: 'Cancel',
    description: 'Description',
    delete: 'Delete',
    title: 'Title',
    save: 'Save',
    next: 'Next',
    previous: 'Previous',
  },
  form: {
    name: 'Name',
    nameExtraLine: 'Name extra',
    streetAddress: 'Street address',
    city: 'City',
    zip: 'Zipcode',
    country: 'Country',
    phone: 'Phone number',
    email: 'Email',
  },
  misc: {
    comment: 'Comment',
    comments: 'Comments',
  },
  example: {
    JohnDoe: 'John Doe',
    SSN: '020972-827T',
    VAT: '3171501-7',
    street: 'Seventh Avenue 23',
    city: 'New York',
    zip: '00100',
    country: 'US',
    comment: 'This is a example comment',
    phone: '+358123456789',
    email: 'john.doe(at)example.com',
    emails: 'john.doe(at)example.com;jane.doe(at)example.com'
  },
  customers: {
    customer: 'Customer',
    customerCreate: 'Create customer',
    form: {
      SSN: 'SSN',
      VAT: 'VAT ID',
      invoicingEmail: 'Invoicing Email'
    }
  },
};
