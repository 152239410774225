import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'buildings',
    component: () => import('../../EmptyRouterView.vue'),
    name: 'Buildings',
    children: [
      {
        path: '',
        component: () => import('./Buildings.vue'),
        name: 'Buildings--ignore',
      },
      {
        path: 'add',
        component: () => import('../../EmptyRouterView.vue'),
        name: 'Add',
        children: [
          {
            path: '',
            component: () => import('./Add.vue'),
            name: 'Add--ignore',
          },
        ],
      },
      {
        path: ':buildingId',
        component: () => import('../../EmptyRouterView.vue'),
        name: 'Inspect',
        children: [
          {
            path: '',
            component: () => import('./Building.vue'),
            name: 'Inspect--ignore',
          },
          {
            path: 'edit',
            component: () => import('../../EmptyRouterView.vue'),
            name: 'Edit',
            props: true,
            children: [
              {
                path: '',
                component: () => import('./Edit.vue'),
                name: 'Edit--ignore',
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
