import {
  BuildingApi,
  BuildingJsonldRentalBuildingRead,
  BuildingJsonldRentalBuildingWrite,
  BuildingRentalBuildingWrite,
  ApiRentalbuildingsGetCollection200Response as BuildingCollection200,
  BuildingApiApiRentalbuildingsGetCollectionRequest as BuildingCollectionRequest,
  ApiRentalrentalbuildingsIdanalyticsGet200Response as BuildingAnalytics,
} from '@gyhtiot/api-client';
import { AxiosInstance, AxiosResponse } from 'axios';
import {} from '@gyhtiot/api-client/dist/api';

export default class BuildingService {
  private api: BuildingApi;

  constructor(basePath: string, axios: AxiosInstance) {
    this.api = new BuildingApi(undefined, basePath, axios);
  }

  public getBuildings = (
    params?: BuildingCollectionRequest
  ): Promise<AxiosResponse<BuildingCollection200>> => {
    return this.api.apiRentalbuildingsGetCollection(params);
  };

  public getBuilding = (
    id: string
  ): Promise<AxiosResponse<BuildingJsonldRentalBuildingRead>> => {
    return this.api.apiRentalbuildingsIdGet({ id });
  };

  public postBuilding = (
    buildingJsonldRentalBuildingWrite: BuildingJsonldRentalBuildingWrite
  ): Promise<AxiosResponse<BuildingJsonldRentalBuildingRead>> => {
    return this.api.apiRentalbuildingsPost({
      buildingJsonldRentalBuildingWrite,
    });
  };

  public putBuilding = (
    id: string,
    buildingJsonldRentalBuildingWrite: BuildingJsonldRentalBuildingWrite
  ): Promise<AxiosResponse<BuildingJsonldRentalBuildingRead>> => {
    return this.api.apiRentalbuildingsIdPut({
      id,
      buildingJsonldRentalBuildingWrite,
    });
  };

  public patchBuilding = (
    id: string,
    buildingRentalBuildingWrite: BuildingRentalBuildingWrite
  ): Promise<AxiosResponse<BuildingJsonldRentalBuildingRead>> => {
    return this.api.apiRentalbuildingsIdPatch({
      id,
      buildingRentalBuildingWrite,
    });
  };

  public getBuildingStats = (id: string): Promise<AxiosResponse<BuildingAnalytics>> => {
    return this.api.apiRentalrentalbuildingsIdanalyticsGet({ id });
  }

  public getPaymentFormsPdf = (id: string): Promise<AxiosResponse<File>> => {
    return this.api.apiRentalrentalbuildingsIdpaymentFormsGet({ id }, { responseType: 'blob' })
  }
}
