import { App } from 'vue';
import FormInput from './components/FormInput.vue';
import FormSelect from './components/FormSelect.vue';
import FormCheckbox from './components/FormCheckbox.vue';
import FormUpload from './components/FormUpload.vue';
import FormDate from './components/FormDate.vue';

export default (app: App<Element>): void => {
  app.component('FormInput', FormInput);
  app.component('FormSelect', FormSelect);
  app.component('FormCheckbox', FormCheckbox);
  app.component('FormUpload', FormUpload);
  app.component('FormDate', FormDate);
};
