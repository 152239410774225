import { createApp } from 'vue';
import VueApexCharts from "vue3-apexcharts";
import App from './App.vue';
import router from './plugins/router';
import intra from './plugins/intra';
import animate from './plugins/animate';
import clipboard from './plugins/clipboard';
import { plugin as formkit } from '@formkit/vue'
import sentry from './plugins/sentry';
import { installI18n, postInstallI18n } from './plugins/i18n';
import formInstall from './components/form/install';
import pinia from './store';
import mitt from 'mitt';
import './index.css';
import 'animate.css/animate.min.css';

window.APP_VERSION = import.meta.env.VITE_RELEASE;
window.APP_NV = import.meta.env.VITE_NV_HOST;
window.APP_API = import.meta.env.VITE_API;
window.PROD = import.meta.env.VITE_ENV === 'production';

const app = createApp(App);
sentry(app, router);
app.use(pinia);
app.use(router);
app.use(VueApexCharts);
app.use(formkit);
const i18n = installI18n(app);

app.config.globalProperties.NV_HOST = import.meta.env.VITE_NV_HOST;
app.config.globalProperties.VERSION = import.meta.env.VITE_RELEASE;
app.config.globalProperties.API = import.meta.env.VITE_API;
app.config.globalProperties.PROD = import.meta.env.VITE_ENV === 'production';

app.provide('animate', animate);
app.provide('clipboard', clipboard);
app.provide('emitter', mitt());

formInstall(app);

router.isReady().then(() => {
  app.provide('intra', intra());
  app.mount('#app');
  postInstallI18n(i18n);
});
