import {
  ContractApi,
  ApiRentalcontractsIdcancelPatchRequest as ContractCancelRequest,
  ApiRentalcontractsIdactivatePatchRequest as ContractActivateRequest,
  ContractJsonldRentalContractItemRead,
  ContractJsonldRentalContractUpdate,
  ContractJsonldRentalContractWrite,
  ContractRentalContractUpdate,
  ApiRentalcontractsGetCollection200Response as ContractCollection200,
  ContractApiApiRentalcontractsGetCollectionRequest as ContractGetCollectionRequest,
  ContractJsonldRentalContractItemReadRentalAttachmentRead as ContractItem,
  ApiRentalcontractsIdraisespreviewGet200Response as RaiseItem,
  ApiRentalcontractsIdraisePatchRequest as ContractRaiseRequest,
} from '@gyhtiot/api-client';
import { AxiosInstance, AxiosResponse } from 'axios';

export default class ContractService {
  private api: ContractApi;

  constructor(basePath: string, axios: AxiosInstance) {
    this.api = new ContractApi(undefined, basePath, axios);
  }

  public getContracts = (
    params?: ContractGetCollectionRequest
  ): Promise<AxiosResponse<ContractCollection200>> => {
    return this.api.apiRentalcontractsGetCollection(params);
  };

  public getContract = (id: string): Promise<AxiosResponse<ContractItem>> => {
    return this.api.apiRentalcontractsIdGet({ id });
  };

  public getContractRaise = (id: string): Promise<AxiosResponse<RaiseItem>> => {
    return this.api.apiRentalcontractsIdraisespreviewGet({ id }, { headers: { 'Accept': 'application/json' } });
  }

  public getContractPdf = (id: string): Promise<AxiosResponse<any>> => {
    return this.api.apiRentalcontractsIdpdfGet({ id }, { responseType: 'blob' });
  };

  public getContractPaymentFormPdf = (id: string): Promise<AxiosResponse<any>> => {
    return this.api.apiRentalcontractsIdpaymentFormGet({ id }, { responseType: 'blob' })
}

  public postContract = (
    contractJsonldRentalContractWrite: ContractJsonldRentalContractWrite
  ): Promise<AxiosResponse<ContractJsonldRentalContractItemRead>> => {
    return this.api.apiRentalcontractsPost({
      contractJsonldRentalContractWrite,
    });
  };

  public putContract = (
    id: string,
    contractJsonldRentalContractUpdate: ContractJsonldRentalContractUpdate
  ): Promise<AxiosResponse<ContractJsonldRentalContractItemRead>> => {
    return this.api.apiRentalcontractsIdPut({
      id,
      contractJsonldRentalContractUpdate,
    });
  };

  public patchContract = (
    id: string,
    contractRentalContractUpdate: ContractRentalContractUpdate
  ): Promise<AxiosResponse<ContractJsonldRentalContractItemRead>> => {
    return this.api.apiRentalcontractsIdPatch({
      id,
      contractRentalContractUpdate,
    });
  };

  public patchContractRaise = (
    id: string,
    params: ContractRaiseRequest
  ): Promise<AxiosResponse<ContractItem>> => {
    return this.api.apiRentalcontractsIdraisePatch( {
      id: id,
      apiRentalcontractsIdraisePatchRequest: params
    });
  }

  public patchContractActive = (
      id: string,
      params: ContractActivateRequest
  ): Promise<AxiosResponse<ContractItem>> => {
    return this.api.apiRentalcontractsIdactivatePatch({
      id: id,
      apiRentalcontractsIdactivatePatchRequest: params
    });
  }

  public patchContractCancel = (
      id: string,
      params: ContractCancelRequest
  ): Promise<AxiosResponse<ContractItem>> => {
    return this.api.apiRentalcontractsIdcancelPatch({
      id: id,
      apiRentalcontractsIdcancelPatchRequest: params,
    });
  };

  public patchContractSign = (
    id: string
  ): Promise<AxiosResponse<ContractItem>> => {
    return this.api.apiRentalcontractsIdsignPatch({ id, body: {} });
  };
}
