import { defineStore } from 'pinia';
import { CurrencyEnum } from '../../types/currency';

export const useCoreStore = defineStore('core', {
  state: () => ({
    locale: 'en',
    currency: CurrencyEnum.EUR,
    time: {
      // https://momentjs.com/timezone/docs/#/using-timezones/
      zone: 'Europe/Helsinki',
      // https://momentjs.com/docs/#/displaying/format/
      format: 'LL',
    },
  }),
});

export default useCoreStore;
