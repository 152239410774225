import {
  ApartmentApi,
  ApartmentApiApiRentalbuildingsIdapartmentsGetCollectionRequest as ApartmentsForBuildingRequest,
  ApartmentJsonldRentalApartmentItemRead,
  ApartmentJsonldRentalApartmentRead,
  ApartmentJsonldRentalApartmentWrite,
  ApartmentJsonldRentalApartmentReadRentalApartmentItemReadRentalContractCollectionRead as ApartmentRead,
  ApiRentalbuildingsIdapartmentsGetCollection200Response as ApartmentsForBuilding200Response,
} from '@gyhtiot/api-client';
import { AxiosInstance, AxiosResponse } from 'axios';
import {

} from "@gyhtiot/api-client/dist/api";

export default class ApartmentService {
  private api: ApartmentApi;

  constructor(basePath: string, axios: AxiosInstance) {
    this.api = new ApartmentApi(undefined, basePath, axios);
  }

  public getApartmentsByBuildingId = (
    params: ApartmentsForBuildingRequest
  ): Promise<AxiosResponse<ApartmentsForBuilding200Response>> => {
    return this.api.apiRentalbuildingsIdapartmentsGetCollection({ ...params });
  };

  public getApartment = (
    id: string
  ): Promise<AxiosResponse<ApartmentRead>> => {
    return this.api.apiRentalapartmentsIdGet({ id });
  };

  public postApartment = (
    apartmentJsonldRentalApartmentWrite: ApartmentJsonldRentalApartmentWrite
  ): Promise<AxiosResponse<ApartmentJsonldRentalApartmentItemRead>> => {
    return this.api.apiRentalapartmentsPost({
      apartmentJsonldRentalApartmentWrite,
    });
  };

  public putApartment = (
    id: string,
    apartmentJsonldRentalApartmentWrite: ApartmentJsonldRentalApartmentWrite
  ): Promise<AxiosResponse<ApartmentJsonldRentalApartmentRead>> => {
    return this.api.apiRentalapartmentsIdPut({
      id,
      apartmentJsonldRentalApartmentWrite,
    });
  };

  public patchApartment = (
    id: string,
    apartmentRentalApartmentWrite: ApartmentJsonldRentalApartmentWrite
  ): Promise<AxiosResponse<ApartmentJsonldRentalApartmentRead>> => {
    return this.api.apiRentalapartmentsIdPatch({
      id,
      apartmentRentalApartmentWrite,
    });
  };
}
